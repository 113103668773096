import React from "react";
import Grid from "@mui/material/Grid2";
import { Box, Typography } from "@mui/material";
import styles from "./LandingPage.module.css";
import { AboutUsLine } from "../../svgImage";
import aboutus from "../../assets/aboutUs.jpg";

const AboutUsComponent = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      marginTop="100px"
      padding="25px"
    >
      <Grid size={{ xs: 12, sm: 10, md: 5, lg: 4 }}>
        <Grid
          container
          direction="column"
          rowSpacing={{xs:"10px",sm:"30px",md:"15px",lg:"40px"}}
        >
          <Grid >
            <Box display="flex" alignItems="center">
              <AboutUsLine />
              <Typography className={styles.Aboutustext} paddingLeft={1}>
                About us
              </Typography>
            </Box>
          </Grid>
          <Grid >
            <Typography className={styles.AboutUsShortDiscription}>
              Experience the comforting flavors of home, one delightful bite at
              a time!
            </Typography>
          </Grid>
          <Grid >
            <Typography className={styles.AboutUsDiscription}>
              At Prasadam, we believe that homemade means heartfelt. We take
              pride in crafting each order with precision, ensuring it brings
              warmth to your table and happiness to your taste buds. Whether
              you're celebrating a special occasion or simply craving a
              delicious treat, we have something for everyone.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12, sm: 10, md: 5, lg: 4 }}   >
        <img src={aboutus} alt="about-us" className={styles.aboutUsImage} />
      </Grid>
    </Grid>
  );
};

export default AboutUsComponent;
