import React from "react";
import Grid from "@mui/material/Grid2";
import styles from "./LandingPage.module.css";
import { Box, Card, Typography, useMediaQuery } from "@mui/material";
import {
  AboutUsLine,
  DefxLogo,
  GmailLogo,
  InstagramLogo,
  WhatasappIcon,
} from "../../svgImage";
import defxLogo from "../../assets/DefxLogo.png";

const FooterComponent = () => {
  const handleGmailCardClick = () => {
    const email = "shreeprasadam135@gmail.com";
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}`;
    window.open(gmailUrl, "_blank");
  };
  const isMobile = useMediaQuery("(max-width:468px)");
  const handleCallClick = () => {
    const phoneNumber = "+918980593216";
    const telUrl = `tel:${phoneNumber}`;
    window.location.href = telUrl;
  };

  const handleWhatsappClick = () => {
    const phoneNumber = "+918980593216";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  const handleInstagramProfileClick = () => {
    const instagramProfileUrl = "https://www.instagram.com/prasadam_by_bhavi/";
    window.open(instagramProfileUrl, "_blank");
  };
  const onDirectToDefx = () => {
    window.location.href = "https://defx.in/";
  };
  return (
    <Grid
      container
      marginTop="100px"
      className={styles.FooterContainer}
      justifyContent="center"
      rowGap={3}
    >
      <Grid size={12}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <AboutUsLine color="#F5CF8E" />
          <Typography
            className={styles.contactUsTitle}
            paddingLeft={isMobile ? 1 : 5}
            paddingRight={isMobile ? 1 : 5}
          >
            Contact us
          </Typography>
          <AboutUsLine rotate="180" color="#F5CF8E" />
        </Box>
      </Grid>
      <Grid size={{ xs: 10, sm: 10, md: 10, lg: 5 }}>
        {/* <Box display="flex" alignItems="center" justifyContent="center"> */}
        <Typography className={styles.contactusDiscription}>
          We’d love to hear from you! Whether you have a question about our
          products, need to place a special order, or just want to say hello,
          feel free to reach out. You can contact us via email, phone, or by
          filling out the form below, and we'll get back to you as soon as
          possible.
        </Typography>
        {/* </Box> */}
      </Grid>
      <Grid size={12}>
        <Grid container justifyContent="center">
          <Grid size={{ xs: 10, sm: 10, md: 8, lg: 8 }}>
            <Grid
              container
              sx={{
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "space-between",
                },
              }}
              rowGap={5}
              columnGap={2}
            >
              <Grid>
                <Card
                  className={styles.footerContactCard}
                  onClick={handleWhatsappClick}
                >
                  <Box className={styles.ContactIconContainer}>
                    <WhatasappIcon />
                  </Box>
                  <Typography className={styles.ContactCardText}>
                    +91-89805 93216
                  </Typography>
                </Card>
              </Grid>
              <Grid>
                <Card
                  className={styles.footerContactCard}
                  onClick={handleInstagramProfileClick}
                >
                  <Box className={styles.ContactIconContainer}>
                    <InstagramLogo />
                  </Box>
                  <Typography className={styles.ContactCardText}>
                    @prasadam_by_bhavi
                  </Typography>
                </Card>
              </Grid>
              <Grid>
                <Card
                  className={styles.footerContactCard}
                  onClick={handleGmailCardClick}
                >
                  <Box className={styles.ContactIconContainer}>
                    <GmailLogo />
                  </Box>
                  <Typography className={styles.ContactCardText}>
                    shreeprasadam135@gmail.com
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12} className={styles.footerTermAndCondition}>
        <Grid container justifyContent="center">
          <Grid size={{ xs: 10, sm: 10, md: 8, lg: 8 }}>
            <Grid
              container
              sx={{
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "space-between",
                },
              }}
              columnGap={2}
              alignItems="center"
              rowGap={2}
            >
              <Grid>
                <Typography className={styles.FooterCopyRightText}>
                  ©Copyright Shree Prasadam
                </Typography>
              </Grid>
              <Grid>
                <Typography className={styles.FooterLogoText}>
                  Shree Prasadam
                </Typography>
              </Grid>
              <Grid>
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={onDirectToDefx}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography className={styles.CarftedByText}>
                    Crafted by
                  </Typography>
                  <img
                    src={defxLogo}
                    alt="defx"
                    style={{ paddingLeft: "5px", paddingBottom: "2px" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FooterComponent;
