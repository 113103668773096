export const AboutUsLine = ({ color, rotate, width }) => {
  return (
    <svg
      width={width ? width : "116"}
      height="6"
      viewBox="0 0 116 6"
      fill="none"
      transform={`rotate(${rotate})`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM3 3.5H116V2.5H3V3.5Z"
        fill={color ? color : "#986633"}
      />
    </svg>
  );
};

export const WhatasappIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="45" height="45" rx="22.5" fill="#986633" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4419 22.2558C14.4419 17.6706 18.159 13.9535 22.7442 13.9535C27.3294 13.9535 31.0465 17.6706 31.0465 22.2558C31.0465 26.841 27.3294 30.5581 22.7442 30.5581C21.1109 30.5581 19.5905 30.0875 18.3078 29.2748C18.0691 29.1236 17.7765 29.0832 17.5058 29.1639L14.6936 30.0033L15.7524 27.5256C15.8775 27.2328 15.8521 26.8974 15.6842 26.6268C14.8967 25.358 14.4419 23.8615 14.4419 22.2558ZM22.7442 12C17.0801 12 12.4884 16.5917 12.4884 22.2558C12.4884 24.0512 12.9505 25.7411 13.7627 27.2105L12.0786 31.151C11.9297 31.4994 11.9957 31.9024 12.2479 32.1851C12.5001 32.4679 12.8931 32.5792 13.2561 32.4708L17.6509 31.1593C19.1522 32.0198 20.892 32.5116 22.7442 32.5116C28.4083 32.5116 33 27.92 33 22.2558C33 16.5917 28.4083 12 22.7442 12ZM24.9834 24.3875L23.6997 25.2916C23.0985 24.9492 22.4339 24.4712 21.7671 23.8044C21.0741 23.1113 20.5602 22.3958 20.1813 21.7394L20.9971 21.047C21.3471 20.7499 21.443 20.2489 21.2273 19.8435L20.1879 17.89C20.0479 17.627 19.7956 17.4422 19.5025 17.3882C19.2095 17.3343 18.9078 17.417 18.6833 17.6129L18.3752 17.8819C17.6341 18.5287 17.1958 19.5915 17.5591 20.6677C17.9357 21.7834 18.7394 23.5393 20.3859 25.1858C22.1573 26.9572 23.9566 27.6548 25.0089 27.9257C25.8567 28.1439 26.659 27.8514 27.2243 27.3908L27.8021 26.92C28.0492 26.7187 28.1825 26.4093 28.1593 26.0914C28.1359 25.7735 27.9589 25.4869 27.685 25.3237L26.0458 24.3469C25.7147 24.1497 25.2986 24.1655 24.9834 24.3875Z"
        fill="white"
      />
    </svg>
  );
};
export const InstagramLogo = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="45" height="45" rx="22.5" fill="#986633" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 16.2C12 13.8804 13.8804 12 16.2 12H28.8C31.1196 12 33 13.8804 33 16.2V28.8C33 31.1196 31.1196 33 28.8 33H16.2C13.8804 33 12 31.1196 12 28.8V16.2ZM16.2 14.1C15.0402 14.1 14.1 15.0402 14.1 16.2V28.8C14.1 29.9598 15.0402 30.9 16.2 30.9H28.8C29.9598 30.9 30.9 29.9598 30.9 28.8V16.2C30.9 15.0402 29.9598 14.1 28.8 14.1H16.2ZM22.5 19.35C20.7603 19.35 19.35 20.7603 19.35 22.5C19.35 24.2397 20.7603 25.65 22.5 25.65C24.2397 25.65 25.65 24.2397 25.65 22.5C25.65 20.7603 24.2397 19.35 22.5 19.35ZM17.25 22.5C17.25 19.6005 19.6005 17.25 22.5 17.25C25.3995 17.25 27.75 19.6005 27.75 22.5C27.75 25.3995 25.3995 27.75 22.5 27.75C19.6005 27.75 17.25 25.3995 17.25 22.5ZM28.275 18.3C29.1448 18.3 29.85 17.5949 29.85 16.725C29.85 15.8551 29.1448 15.15 28.275 15.15C27.4052 15.15 26.7 15.8551 26.7 16.725C26.7 17.5949 27.4052 18.3 28.275 18.3Z"
        fill="white"
      />
    </svg>
  );
};

export const GmailLogo = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="45" height="45" rx="22.5" fill="#986633" />
      <path
        d="M13.1667 15.9834L22.5 22.8667L31.8334 15.9834"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.6667 14H14.3333C13.0447 14 12 15.0447 12 16.3333V28C12 29.2887 13.0447 30.3333 14.3333 30.3333H30.6667C31.9553 30.3333 33 29.2887 33 28V16.3333C33 15.0447 31.9553 14 30.6667 14Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
