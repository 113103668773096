import './App.css';
import LandingPage from './component/LandingPage/LandingPage';

function App() {
  return (
    <div className='body'>
      <LandingPage />
    </div>
  );
}

export default App;
